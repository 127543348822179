<template>
  <b-card no-body>
    <b-card-body>
      <v-select
        v-model="id_webforms"
        :options="webforms"
        placeholder="Filtrele"
        label="title"
        :reduce="item => item.id"
        @input="getDataList"
      />
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(company_name)="data">
        {{ data.item.company_name ? data.item.company_name : data.item.name_surname }}
        <div>
          <b-badge
            v-if="data.item.company_name && data.item.name_surname"
            variant="light-primary mr-1 mt-1"
          >
            <feather-icon icon="UserIcon" />
            {{ data.item.name_surname }}
          </b-badge>
          <b-badge
            v-if="data.item.webforms"
            variant="light-danger mr-1 mt-1"
          >
            <feather-icon icon="FileIcon" />
            {{ data.item.webforms }}
          </b-badge>
          <b-badge
            v-if="data.item.created"
            variant="light-secondary mr-1 mt-1"
          >
            <feather-icon icon="CalendarIcon" />
            {{ moment(data.item.created).format('DD.MM.YYYY') }}
          </b-badge>
        </div>
      </template>
      <template #cell(phone)="data">
        <b-badge variant="light-success mr-1">
          <feather-icon icon="PhoneIcon" />
          {{ data.item.phone }}
        </b-badge>
        <div class="mt-1">
          <b-badge
            v-if="data.item.email"
            variant="light-dark mr-1"
          >
            <feather-icon icon="MailIcon" />
            {{ data.item.email }}
          </b-badge>
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="outline-primary"
            size="sm"
            :to="$route.path + '/view/' + data.item.id"
          >
            <feather-icon icon="SearchIcon" />
            Görüntüle
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BPagination, BCardFooter, BBadge, BButton, BCardBody,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

const tableName = 'webform_requests'
export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BBadge,
    BButton,
    BCardBody,
    vSelect,
  },
  data() {
    return {
      id_webforms: null,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'company_name',
          label: 'Firma Adı / Adı Soyadı',
        },
        {
          key: 'phone',
          label: 'İletişim Bilgileri',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.company_name AS company_name`,
          `${tableName}.name_surname AS name_surname`,
          `${tableName}.email AS email`,
          `${tableName}.phone AS phone`,
          'webforms.title AS webforms',
          `${tableName}.created AS created`,

        ],
        where: {
          'webform_requests.id_webforms !=': null,
        },
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    webforms() {
      return this.$store.getters['webforms/dataList']
    },
    dataList() {
      return this.$store.getters['webformRequests/dataList']
    },
    dataCounts() {
      return this.$store.getters['webformRequests/dataCounts']
    },
    saveData() {
      return this.$store.getters['webformRequests/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    if (this.$route.path === '/app/download-requests') {
      this.id_webforms = '11'
    } else {
      this.id_webforms = null
    }
    this.getWebforms()
    this.getDataList()
  },
  methods: {
    getWebforms() {
      this.$store.dispatch('webforms/getDataList')
    },
    getDataList() {
      if (this.id_webforms) {
        this.dataQuery.where = {
          'webform_requests.id_webforms': this.id_webforms,
        }
      } else {
        this.dataQuery.where = {
          'webform_requests.id_webforms !=': null,
        }
      }
      this.$store.dispatch('webformRequests/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('webformRequests/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
